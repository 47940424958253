export const QuestionsAnswersVideo = [
  {
    question: 'Что входит в пакет за 11$?',
    bold: [true, 1],
    answer:
      ['Вы выбираете количество товаров, для которых под которое вам необходимо создать видео.',
      '1 товар = 1 видео.',
      'В ежемесячную подписку входят:',
      '1. Создание 1000 новых роликов или обновление старых',
      '2. Доступ к видеоредактору для создания видео для промо',
      '3. Хранение видео на серверах Softcube',
      '4. Передача видео в eSputnik'],
  },
  {
    question: 'Что делать, если мне нужно больше 1000 роликов?',
    bold: [false, 1],
    answer:
      ['Каждая дополнительная 1000 стоит 11$.',
      'Например, вам нужно создать видео для 10 000 товаров, тогда в первый месяц платите 110$ и далее вы платите по 11$ за хранение и процессинг.'],
  },
  {
    question: 'Мне нужно скачивать видео и вручную вставлять его в email рассылку?',
    bold: [false, 1],
    answer:
      ['Нет, видео автоматически попадут в динамический шаблон вашего письма в системе eSputnik.'],
  },
  {
    question: 'Можно ли сделать видео под самые популярные товары, а не под весь ассортимент?',
    bold: [false, 1],
    answer:
      ['Да, вы можете самостоятельно выбрать товары,  под которые вам необходимо создать видео. '],
  },
  {
    question: 'Как происходит техническая интеграция?',
    bold: [false, 1],
    answer:
      ['От вас потребуется товарный фид со всеми доступными фото под каждый товар.',
      'Если ваши программисты не хотят добавлять фото в фид или говорят,  что это будет долго, сообщите нам об этом и мы что-то придумаем:) '],
  },
  {
    question: 'Что делать,  если у меня только одно фото у товара?',
    bold: [false, 1],
    answer:
      ['У нас есть специальные шаблоны с графической анимацией под одно фото.'],
  },
];

